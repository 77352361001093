#g-recaptcha,
#pricingCaptcha,
#contactCaptcha {
  display: none !important;
}

.ReactModal__Overlay {
  transition: transform 300ms ease-in-out;
  transition-delay: 100ms;
  transform: scale(0);
}

.ReactModal__Overlay--after-open {
  transform: scale(1);
}

.ReactModal__Overlay--before-close {
  transform: scale(0);
}

div[role='alertdialog'] {
  border-radius: 10px;
}
